import { all, fork } from 'redux-saga/effects';

import * as authSagas from "./sagas/auth";
import * as shopSagas from "./sagas/shop";

export default function* rootSaga() {
  yield all([
    ...Object.values(authSagas),
    ...Object.values(shopSagas),
  ].map(fork));
}