import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//     ____      _ __ 
//    /  _/___  (_) /_
//    / // __ \/ / __/
//  _/ // / / / / /_  
// /___/_/ /_/_/\__/  

export function* watchInit() {
    yield takeLatest("INIT", trackInit);
}

function* trackInit(sagaData) {
    try {
        var response = yield call(callInit, sagaData.payload);

        if (response) {
            yield put({
                type: "INIT_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "INIT_ERROR",
            response: err
        });
    }
}

function callInit(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/init", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __                _     
//    / /   ____  ____ _(_)___ 
//   / /   / __ \/ __ `/ / __ \
//  / /___/ /_/ / /_/ / / / / /
// /_____/\____/\__, /_/_/ /_/ 
//             /____/          

export function* watchLogin() {
    yield takeLatest("LOGIN", trackLogin);
}

function* trackLogin(sagaData) {
    try {
        var response = yield call(callLogin, sagaData.payload);

        if (response) {
            yield put({
                type: "LOGIN_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "LOGIN_ERROR",
            response: err
        });
    }
}

function callLogin(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/login", saga).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//   ______                  __        _____ __        __            
//  /_  __/___  ____ _____ _/ /__     / ___// /_____ _/ /___  _______
//   / / / __ \/ __ `/ __ `/ / _ \    \__ \/ __/ __ `/ __/ / / / ___/
//  / / / /_/ / /_/ / /_/ / /  __/   ___/ / /_/ /_/ / /_/ /_/ (__  ) 
// /_/  \____/\__, /\__, /_/\___/   /____/\__/\__,_/\__/\__,_/____/  
//           /____//____/                                            

export function* watchToggleStatus() {
    yield takeLatest("TOGGLE_STATUS", trackToggleStatus);
}

function* trackToggleStatus(sagaData) {
    try {
        var response = yield call(callToggleStatus, sagaData.payload);

        if (response) {
            yield put({
                type: "TOGGLE_STATUS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "TOGGLE_STATUS_ERROR",
            response: err
        });
    }
}

function callToggleStatus(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/toggle-status", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______     __     ____                 __           __      
//   / ____/__  / /_   / __ \_________  ____/ /_  _______/ /______
//  / / __/ _ \/ __/  / /_/ / ___/ __ \/ __  / / / / ___/ __/ ___/
// / /_/ /  __/ /_   / ____/ /  / /_/ / /_/ / /_/ / /__/ /_(__  ) 
// \____/\___/\__/  /_/   /_/   \____/\__,_/\__,_/\___/\__/____/  

export function* watchGetProducts() {
    yield takeLatest("GET_PRODUCTS", trackGetProducts);
}

function* trackGetProducts(sagaData) {
    try {
        var response = yield call(callGetProducts, sagaData.payload);

        if (response) {
            yield put({
                type: "GET_PRODUCTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "GET_PRODUCTS_ERROR",
            response: err
        });
    }
}

function callGetProducts(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/auth/get-products", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______     __     ____                                      
//   / ____/__  / /_   / __ \_________  ____ _________  __________
//  / / __/ _ \/ __/  / /_/ / ___/ __ \/ __ `/ ___/ _ \/ ___/ ___/
// / /_/ /  __/ /_   / ____/ /  / /_/ / /_/ / /  /  __(__  |__  ) 
// \____/\___/\__/  /_/   /_/   \____/\__, /_/   \___/____/____/  
//                                   /____/                       

export function* watchGetProgress() {
    yield takeLatest("GET_PROGRESS", trackGetProgress);
}

function* trackGetProgress(sagaData) {
    try {
        var response = yield call(callGetProgress, sagaData.payload);

        if (response) {
            yield put({
                type: "GET_PROGRESS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "GET_PROGRESS_ERROR",
            response: err
        });
    }
}

function callGetProgress(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/auth/get-progress", {
            params: saga,
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//     __  ___                                _____      __          __           __
//    /  |/  /___ _____  ____ _____ ____     / ___/___  / /__  _____/ /____  ____/ /
//   / /|_/ / __ `/ __ \/ __ `/ __ `/ _ \    \__ \/ _ \/ / _ \/ ___/ __/ _ \/ __  / 
//  / /  / / /_/ / / / / /_/ / /_/ /  __/   ___/ /  __/ /  __/ /__/ /_/  __/ /_/ /  
// /_/  /_/\__,_/_/ /_/\__,_/\__, /\___/   /____/\___/_/\___/\___/\__/\___/\__,_/   
//                          /____/                                                  

export function* watchManageSelected() {
    yield takeLatest("MANAGE_SELECTED", trackManageSelected);
}

function* trackManageSelected(sagaData) {
    try {
        var response = yield call(callManageSelected, sagaData.payload);

        if (response) {
            yield put({
                type: "MANAGE_SELECTED_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "MANAGE_SELECTED_ERROR",
            response: err
        });
    }
}

function callManageSelected(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/auth/manage-selected", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}