import { Storage } from './helpers';
import Swal from "sweetalert2";

const rootReducer = (state = {}, action) => {
    if (action.type.includes('SUCCESS') && action.response?.message) {
        Swal.fire({
            icon: "success",
            title: action.response.message,
            timer: 3000,
            showConfirmButton: false
        });
    } else if (action.type.includes('ERROR') && action.response?.message) {
        if (action.response?.message === "getaddrinfo ENOTFOUND null" || action.response?.message === "getaddrinfo EAI_AGAIN null") {
            Swal.fire({
                icon: "error",
                title: "Please open the app through your shopify store."
            }).then((result) => {
                let currentState = JSON.parse(Storage.Fetch('state'));
                window.location.href = `https://${currentState.auth.shop}/admin/apps`;
                sessionStorage.clear();
            });
        } else if (action.response?.message === "jwt expired") {
            Swal.fire({
                icon: "error",
                title: "Your login session has expired. Please login again to continue."
            }).then((result) => {
                let currentState = JSON.parse(Storage.Fetch('state'));
                window.location.href = `https://${currentState.auth.shop}/admin/apps`;
                sessionStorage.clear();
            });
        } else {
            Swal.fire({
                icon: "error",
                title: action.response.message
            });
        }
    }

    switch (action.type) {
        case 'INIT_SUCCESS':
            return {
                init: action.response.data
            };
        case 'INIT_ERROR':
            return Object.assign({}, {
                ...state,
                init: {
                    loading: false
                }
            });
        case 'LOGIN_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'LOGIN_ERROR':
            return Object.assign({}, {
                ...state,
                auth: {
                    loading: false
                }
            });
        case 'CREATE_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                charge: action.response.data
            });
        case 'CREATE_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state,
                charge: {
                    loading: false
                }
            });
        case 'SET_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'SET_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'CANCEL_RECURRING_CHARGE_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'CANCEL_RECURRING_CHARGE_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'GET_SHOPIFY_PRODUCTS_SUCCESS':
            return Object.assign({}, {
                ...state
            });
        case 'GET_SHOPIFY_PRODUCTS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'TOGGLE_STATUS_SUCCESS':
            return Object.assign({}, {
                ...state,
                auth: action.response.data
            });
        case 'TOGGLE_STATUS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'GET_PRODUCTS_SUCCESS':
            return Object.assign({}, {
                ...state,
                products: action.response.data
            });
        case 'GET_PRODUCTS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'GET_PROGRESS_SUCCESS':
            return Object.assign({}, {
                ...state,
                progress: action.response.data
            });
        case 'GET_PROGRESS_ERROR':
            return Object.assign({}, {
                ...state
            });
        case 'MANAGE_SELECTED_SUCCESS':
            return Object.assign({}, {
                ...state,
                selected: action.response.data
            });
        case 'MANAGE_SELECTED_ERROR':
            return Object.assign({}, {
                ...state,
                selected: action.response.data
            });
        default:
            return state;
    }
}

export default rootReducer;