import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { Storage } from '../helpers';

//    ______                __          ____                            _                ________                        
//   / ____/_______  ____ _/ /____     / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____ 
//  / /   / ___/ _ \/ __ `/ __/ _ \   / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
// / /___/ /  /  __/ /_/ / /_/  __/  / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// \____/_/   \___/\__,_/\__/\___/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/ 
//                                                                          /____/                          /____/       

export function* watchCreateRecurringCharge() {
    yield takeLatest("CREATE_RECURRING_CHARGE", trackCreateRecurringCharge);
}

function* trackCreateRecurringCharge(sagaData) {
    try {
        var response = yield call(callCreateRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "CREATE_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CREATE_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callCreateRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/create-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    _____      __     ____                            _                ________                        
//   / ___/___  / /_   / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____ 
//   \__ \/ _ \/ __/  / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
//  ___/ /  __/ /_   / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// /____/\___/\__/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/ 
//                                                          /____/                          /____/       

export function* watchSetRecurringCharge() {
    yield takeLatest("SET_RECURRING_CHARGE", trackSetRecurringCharge);
}

function* trackSetRecurringCharge(sagaData) {
    try {
        var response = yield call(callSetRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "SET_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "SET_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callSetRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/set-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______                      __   ____                            _                ________                        
//   / ____/___ _____  ________  / /  / __ \___  _______  ____________(_)___  ____ _   / ____/ /_  ____ __________ ____ 
//  / /   / __ `/ __ \/ ___/ _ \/ /  / /_/ / _ \/ ___/ / / / ___/ ___/ / __ \/ __ `/  / /   / __ \/ __ `/ ___/ __ `/ _ \
// / /___/ /_/ / / / / /__/  __/ /  / _, _/  __/ /__/ /_/ / /  / /  / / / / / /_/ /  / /___/ / / / /_/ / /  / /_/ /  __/
// \____/\__,_/_/ /_/\___/\___/_/  /_/ |_|\___/\___/\__,_/_/  /_/  /_/_/ /_/\__, /   \____/_/ /_/\__,_/_/   \__, /\___/ 
//                                                                         /____/                          /____/       

export function* watchCancelRecurringCharge() {
    yield takeLatest("CANCEL_RECURRING_CHARGE", trackCancelRecurringCharge);
}

function* trackCancelRecurringCharge(sagaData) {
    try {
        var response = yield call(callCancelRecurringCharge, sagaData.payload);

        if (response) {
            yield put({
                type: "CANCEL_RECURRING_CHARGE_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "CANCEL_RECURRING_CHARGE_ERROR",
            response: err
        });
    }
}

function callCancelRecurringCharge(saga) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/shop/cancel-recurring-charge", saga, {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}

//    ______     __     _____ __                _ ____         ____                 __           __      
//   / ____/__  / /_   / ___// /_  ____  ____  (_) __/_  __   / __ \_________  ____/ /_  _______/ /______
//  / / __/ _ \/ __/   \__ \/ __ \/ __ \/ __ \/ / /_/ / / /  / /_/ / ___/ __ \/ __  / / / / ___/ __/ ___/
// / /_/ /  __/ /_    ___/ / / / / /_/ / /_/ / / __/ /_/ /  / ____/ /  / /_/ / /_/ / /_/ / /__/ /_(__  ) 
// \____/\___/\__/   /____/_/ /_/\____/ .___/_/_/  \__, /  /_/   /_/   \____/\__,_/\__,_/\___/\__/____/  
//                                   /_/          /____/                                                  
                                                               
export function* watchGetShopifyProducts() {
    yield takeLatest("GET_SHOPIFY_PRODUCTS", trackGetShopifyProducts);
}

function* trackGetShopifyProducts(sagaData) {
    try {
        var response = yield call(callGetShopifyProducts, sagaData.payload);

        if (response) {
            yield put({
                type: "GET_SHOPIFY_PRODUCTS_SUCCESS",
                response: response
            });
        }
    } catch (err) {
        yield put({
            type: "GET_SHOPIFY_PRODUCTS_ERROR",
            response: err
        });
    }
}

function callGetShopifyProducts(saga) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/shop/get-shopify-products", {
            headers: {
                "Authorization": "Bearer " + Storage.Fetch("token")
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err.response.data);
        });
    })
}