import React, { useContext } from "react";
import { Loader, LoaderContext } from "../loader";

function DashboardLayout(props) {
    const { loading } = useContext(LoaderContext);

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <div className="container py-5">
                {props.children}
            </div>
        </React.Fragment>
    )
}

export default DashboardLayout;