import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

import { LoaderContext } from '../loader';
import DashboardLayout from '../layouts/dashboard-layout';

function Dashboard(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const authProps = useSelector(state => state.auth);
    const progressProps = useSelector(state => state.progress);
    const productProps = useSelector(state => state.products);
    const selectedProps = useSelector(state => state.selected);
    const [products, setProducts] = useState({});
    const { setLoading } = useContext(LoaderContext);

    let location = new URLSearchParams(useLocation().search);
    let chargeID = location.get("charge_id");

    // Pagination
    const limit = 20;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (chargeID !== null) {
            dispatch({
                type: "SET_RECURRING_CHARGE",
                payload: {
                    id: chargeID
                }
            });

            history.push('/dashboard');
        }
    }, [chargeID]);

    useEffect(() => {
        setLoading(true);
        GetProducts();
    }, [page, selectedProps]);

    useEffect(() => {
        setLoading(false);
        if (!isEmpty(productProps) && productProps.results !== undefined) {
            setProducts(productProps.results);
            setTotal(productProps.total)
        }
    }, [productProps]);

    function GetProducts() {
        dispatch({
            type: "GET_PRODUCTS",
            payload: {
                page: page - 1,
                limit: limit
            }
        });

        dispatch({
            type: "GET_PROGRESS",
            payload: {}
        });
    }

    function GetProgress() {
        let completed = progressProps?.completed;
        let pending = progressProps?.pending;

        if (completed !== 0) {
            let progress = (completed / (completed + pending)) * 100;
            return progress.toFixed(2);
        } else {
            return 0;
        }
    }

    function GetProgressSelected() {
        let completed = progressProps?.completed;
        let selected = progressProps?.selected;

        if (completed !== 0) {
            let progress = (completed / selected) * 100;
            return progress.toFixed(2);
        } else {
            return 0;
        }
    }

    function UpgradePlan() {
        history.push('/upgrade-plan');
    }

    function CancelPlan() {
        Swal.fire({
            icon: "error",
            title: `Are you sure you want to cancel the plan ?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel"
        }).then((result) => {
            if (result.isConfirmed) {
                history.push('/cancel-plan');
            }
        });
    }

    function CheckLimiter(e) {
        let selectedCount = document.querySelectorAll('input[name="choose_product"]:checked').length;

        if (authProps?.plan_details === undefined) {
            if(e.target.getAttribute('data-completed') == "true") {
                e.preventDefault()
                e.stopPropagation();
            } else if (selectedCount > process.env.REACT_APP_FREE_PLAN_LIMIT) {
                e.preventDefault()
                e.stopPropagation();
            } else {
                ManageSingle(e);
            }
        } else {
            ManageSingle(e);
        }
    }

    function ManageSingle(e) {
        dispatch({
            type: "MANAGE_SELECTED",
            payload: {
                type: 'page',
                selection: e.target.checked ? 'select' : 'unselect',
                product_ids: [e.target.value]
            }
        });
    }

    function SelectionManager(type, selection) {
        if (type === 'page') {
            let product_ids = [];
            let selected = document.querySelectorAll('input[name="choose_product"]:checked');
            for (let i = 0; i < selected.length; i++) {
                product_ids.push(selected[i].value);

                if (selection === "unselect") {
                    selected[i].checked = false;
                }
            }

            dispatch({
                type: "MANAGE_SELECTED",
                payload: {
                    type: type,
                    selection: selection,
                    product_ids: product_ids
                }
            });
        } else if (type === "all") {
            dispatch({
                type: "MANAGE_SELECTED",
                payload: {
                    type: type,
                    selection: selection,
                    product_ids: []
                }
            });
        }
    }

    function SelectAll(type) {
        setLoading(true);

        if (type === "page-clear") {
            SelectionManager('page', 'unselect');
        } else if (type === "all-clear") {
            SelectionManager('all', 'unselect');
        } else if (type === "all") {
            let checkboxes = document.querySelectorAll('input[name="choose_product"]');

            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
            }

            SelectionManager('all', 'select');
        } else if (type === "page") {
            let checkboxes = document.querySelectorAll('input[name="choose_product"]');

            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
            }

            SelectionManager('page', 'select');
        }
    }

    return (
        <DashboardLayout {...props}>
            <div className="is-flex is-justify-content-space-between is-align-items-center mb-5">
                <img src="./images/logo.png" alt="smart seo boost logo" />
                <div>
                    {
                        authProps?.plan_details === undefined ?
                            <span className="is-size-5">You are currently on the Free Plan. <a className="has-text-danger has-text-weight-semibold is-clickable" onClick={() => UpgradePlan()}><u>Upgrade Plan Now</u></a></span> :
                            <span className="is-size-5">You are currently on the {authProps?.plan_details?.name} Plan. <a className="has-text-danger has-text-weight-semibold is-clickable" onClick={() => CancelPlan()}><u>Cancel Plan</u></a></span>
                    }
                </div>
            </div>

            <div className="has-text-success my-5 has-text-centered border border-1 border-primary border-dashed p-5 has-background-white">
                <p className="is-size-4 has-text-weight-bold has-text-danger">Please note:</p>
                <span className="is-size-5m6">You may notice the progress bar will stay at a certain percentage for a while. <br /> This is normal as seo optimization may take a number of days/weeks depending upon the number products in store.</span>
            </div>

            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="is-flex is-justify-content-space-between is-align-items-center">
                        <h1 className="title is-size-4 has-text-primary mb-2"> Statistics </h1>
                        {
                            authProps?.plan_details === undefined ?
                                <span className="has-text-weight-semibold has-text-primary is-size-5 mb-2">Product Limit : <span className="has-text-weight-normal has-text-danger">{process.env.REACT_APP_FREE_PLAN_LIMIT} Products</span></span> :
                                <span className="has-text-weight-semibold has-text-primary is-size-5 mb-2">Product Limit : <span className="has-text-weight-normal has-text-danger">Unlimited Products</span></span>
                        }
                    </div>
                    <div className="columns is-multiline">
                        <div className="column">
                            <div className="box has-background-purple-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{total}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Total Products</p>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box has-background-pink-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{progressProps?.selected}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Selected for Optimization</p>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box has-background-blue-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{progressProps?.pending}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Unoptimized Products</p>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box has-background-success-light h-full is-flex is-justify-content-center is-align-items-center has-text-centered">
                                <div>
                                    <p className="title is-size-2">{progressProps?.completed}</p>
                                    <p className="subtitle is-size-6 has-text-weight-medium">Optimized Products</p>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns">
                <div className="column is-6">
                    <div className="box">
                        <div className="is-flex is-justify-content-space-between">
                            <h1 className="title is-size-4 mb-2 has-text-primary"> Selected Products Optimized </h1>
                            <div className="title is-size-4 mb-2 has-text-danger">{GetProgressSelected()}% Complete</div>
                        </div>
                        <progress className="progress is-medium is-primary w-full p-3 mt-2" value={GetProgressSelected()} max="100">{GetProgressSelected()}%</progress>
                    </div>
                </div>

                <div className="column is-6">
                    <div className="box">
                        <div className="is-flex is-justify-content-space-between">
                            <h1 className="title is-size-4 mb-2 has-text-primary"> Total Products Optimized </h1>
                            <div className="title is-size-4 mb-2 has-text-danger">{GetProgress()}% Complete</div>
                        </div>
                        <progress className="progress is-medium is-primary w-full p-3 mt-2" value={GetProgress()} max="100">{GetProgress()}%</progress>
                    </div>
                </div>
            </div>

            <div className="message is-info">
                <div className="message-body">
                    Please select the products you wish to optimize from the product list below, the products with a checkmark next to them will start optimizing automatically
                </div>
            </div>

            {
                authProps?.plan_details !== undefined ?
                    <div className="is-flex is-justify-content-space-around">
                        <div className="mb-5">
                            <button className="button is-success mx-2" onClick={() => SelectAll('all')}>Select all products on Store</button>
                            <button className="button is-danger mx-2" onClick={() => SelectAll('all-clear')}>Unselect all products on Store</button>
                        </div>
                        <div>
                            <button className="button is-success mx-2" onClick={() => SelectAll('page')}>Select all on current page</button>
                            <button className="button is-danger mx-2" onClick={() => SelectAll('page-clear')}>Unselect all on current page</button>
                        </div>
                    </div> : ""
            }

            <div className="box mb-2">
                <div className="is-flex is-justify-content-space-between is-items-align-center">
                    <h1 className="title is-size-4 mb-0 has-text-primary"> Products List </h1>
                    <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={5} onChange={(e) => setPage(e)} />
                </div>
                <hr className="mt-4 mb-1" />
                <div className="table-container">
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Title</th>
                                <th>Handle</th>
                                <th>Product Type</th>
                                <th>Vendor</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !isEmpty(products) && Object.values(products).map((product, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <input type="checkbox" name="choose_product" defaultValue={product?._id} defaultChecked={product?.optimize} onClick={(e) => CheckLimiter(e)} data-completed={product?.completed} />
                                            </td>
                                            <td>{product.title}</td>
                                            <td>{product.handle}</td>
                                            <td>{product.product_type}</td>
                                            <td>{product.vendor}</td>
                                            <td>
                                                {
                                                    product?.completed === false && product?.optimize === false ? <span className="tag is-warning has-text-white">Unoptimized</span> : ""
                                                }
                                                {
                                                    product?.completed === false && product?.optimize === true ? <span className="tag is-info has-tooltip-left">Processing</span> : ""
                                                }
                                                {
                                                    product?.completed === true && product?.optimize === true ? <span className="tag is-success">Optimized</span> : ""
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {
                        isEmpty(products) ? <p className="pb-5 has-text-centered is-fullwidth">No products found</p> : ""
                    }
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Dashboard;